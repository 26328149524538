import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { locations } from "../../Config/DashboardDataConfig";

export default function RaportPage() {
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const [selectedLocation, setSelectedLocation] = useState("0");

  function handleStartDateChange(date: any) {
    setSelectedStartDate(date);
  }

  function handleEndDateChange(date: any) {
    setSelectedEndDate(date);
  }

  function handleLocationChange(location: any) {
    setSelectedLocation(location.target.value);
  }

  return (
    <FormControl className="report-data-container">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          id="mui-pickers-date"
          label="Data start"
          value={selectedStartDate}
          format="dd/MM/yyyy"
          onChange={handleStartDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          fullWidth={true}
        />
      </MuiPickersUtilsProvider>
      {/* 2 */}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          id="mui-pickers-date"
          label="Data final"
          value={selectedEndDate}
          format="dd/MM/yyyy"
          onChange={handleEndDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          fullWidth={true}
        />
      </MuiPickersUtilsProvider>
      <FormControl fullWidth={true} style={{ marginTop: 10 }}>
        <InputLabel htmlFor="age-simple">Sediu</InputLabel>
        <Select
          value={selectedLocation}
          onChange={handleLocationChange}
          inputProps={{
            name: "age",
            id: "age-simple",
          }}
        >
          {locations.map(({ id, name }) => (
            <MenuItem value={id} key={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <a
        href={`http://service.e-twow.app/genereaza_pdf.php?start=${selectedStartDate}&stop=${selectedEndDate}&selectedLocation=${selectedLocation}`}
        download="Da"
        style={{ width: "100%" }}
      >
        <Button
          style={{ right: 0, width: "100%", marginTop: 30 }}
          variant="contained"
          color="primary"
        >
          Generare raport
          <ArrowForwardIcon style={{ marginLeft: 5 }} />
        </Button>
      </a>
    </FormControl>
  );
}
