import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import SuRoute from "./SuRoute";
import Navbar from "./Navbar";
import Dashboard from "../Pages/Dashboard/Main";
import Login from "../Pages/Auth/Login";
import CustomerData from "../Pages/Dashboard/ClientDataPage";
import CustomerServiceData from "../Pages/Dashboard/ServiceDataPage";
import DefectsData from "../Pages/DefectsPage";
import PartsData from "../Pages/PartsPage";
import Report from "../Pages/RaportPage";
import AWB from "../Pages/Dashboard/AwbDataPage";

export default function Routes() {
  return (
    <Router>
      <div>
        <Navbar />
        <div style={{ display: "flex", flexDirection: "row" }}>
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <main className="content_container">
            <Switch>
              <PrivateRoute path="/customer/:id" component={CustomerData} />
              <SuRoute path="/defects" component={DefectsData} />
              <SuRoute path="/parts" component={PartsData} />
              <SuRoute path="/generate-report" component={Report} />

              <PrivateRoute
                path="/customer-service/:id"
                component={CustomerServiceData}
              />
              <PrivateRoute path="/sheet-awb/:id" component={AWB} />
              <Route path="/login" component={Login} />

              <PrivateRoute path="/" component={Dashboard} />
            </Switch>
          </main>
        </div>
      </div>
    </Router>
  );
}
