import React from "react";
import { Route, Redirect } from "react-router-dom";
import useAuth from "../Context/AuthContext/useAuth";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { loggedCx } = useAuth()!;

  return (
    <Route
      {...rest}
      render={(props: any) =>
        loggedCx === true ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
