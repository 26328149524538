import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import ReplacedParts from "./ReplacedParts";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  FormControlLabel,
  FormControl,
  Checkbox,
  Button,
  TextField,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import ReactLoading from "react-loading";
import useAuth from "../../../Context/AuthContext/useAuth";
import axios from "axios";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function ClientServiceData() {
  interface ParamTypes {
    id: string | undefined;
  }

  const { id: pageId } = useParams<ParamTypes>();
  let history = useHistory();
  const classes = useStyles();

  const [values, setValues] = useState({
    cauzeDefect: [],
    ReplacedParts: "",
    tipGarantie: "1",
    recomandari: "",
  });
  const [products, setProducts] = useState([]);
  const [defect, setDefect] = useState([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [parts, setParts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sheetStatus, setSheetStatus] = useState<number | null>(null);

  const { userRoleCx, adminIdCx } = useAuth()!;

  const disableInputs =
    (sheetStatus === 4 && !userRoleCx?.includes("4")) ||
    !(userRoleCx?.includes("2") || userRoleCx?.includes("4"));

  const handleChange = (prop: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  function handleDateChange(date: Date | null) {
    setSelectedDate(date ?? new Date());
  }

  // id piesa/ GARANTIE/ CANTIT
  async function updateFisa() {
    const status = products.length === 0 ? "2" : "3";

    // id defect piesa
    const idDefect = values.cauzeDefect.map((defect: { id_defect: number }) => {
      return { id_defect: defect?.id_defect };
    });

    // date piesa

    interface ProductsTypes {
      id_piesa: number;
      cant: number;
      garantie: boolean;
    }

    const piesa = products.map((product: ProductsTypes) => {
      return {
        id_piesa: product.id_piesa,
        cantitate_piesa: product.cant,
        garantie_piesa: product.garantie,
      };
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const reservationData = {
      id_fisa: pageId,
      defect: idDefect,
      piese: piesa,
      tip_garantie: values.tipGarantie,
      recomandari: values.recomandari,
      status: status,
      id_admin: adminIdCx,
      data_selectata: selectedDate,
    };

    try {
      const res = await axios.post(
        `/update_service.php`,
        reservationData,
        config
      );
      if (res.status === 200) history.push("/dashboard");
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    async function dropdownData() {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache",
          Expires: "0",
        },
      };

      try {
        const res = await axios.get(`/defecte.php`, config);
        setDefect(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    async function getParts() {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache",
          Expires: "0",
        },
      };

      try {
        const res = await axios.get(`/piese.php`, config);
        setParts(res.data);
      } catch (error) {
        console.log(error);
      }
    }

    dropdownData();
    getParts();
  }, []);

  useEffect(() => {
    async function getValues() {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache",
          Expires: "0",
        },
      };

      const reservationData = {
        id_fisa: pageId,
      };

      try {
        const res = await axios.post(`/pas2.php`, reservationData, config);
        const data = res.data;

        if (data[0].piese) {
          setProducts(data[0].piese);
        }

        if (data[0].defecte) {
          setValues((values) => ({
            ...values,
            cauzeDefect: data[0].defecte,
            tipGarantie: data[0].garantie,
            recomandari: data[0].observatii,
          }));
        }
        setSelectedDate(data[0].data_selectata ?? new Date());
        setSheetStatus(parseInt(data[0].status));
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    if (defect.length > 0 && parts.length > 0) getValues();
  }, [pageId, parts, defect]);

  return (
    <div className="customer-data-container">
      {loading ? (
        <ReactLoading type={"bubbles"} color={"#3f51b5"} />
      ) : (
        <FormControl style={{ width: "100%", height: "100%" }}>
          <div className="section-style">
            <div className="content-header">
              <h1
                style={{
                  width: "50%",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                5.Cauze defect/Solutii
              </h1>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  style={{ marginLeft: "auto", marginRight: 0 }}
                  margin="none"
                  id="mui-pickers-date"
                  label="Data fisei"
                  value={selectedDate}
                  format="dd/MM/yyyy"
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disabled={disableInputs}
                />
              </MuiPickersUtilsProvider>
            </div>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={defect}
              disableCloseOnSelect
              disabled={disableInputs}
              getOptionLabel={(option: any) => option.defect}
              value={values.cauzeDefect}
              onChange={(event, value: any) =>
                setValues({ ...values, cauzeDefect: value })
              }
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.defect}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label=""
                  placeholder="Defecte"
                  fullWidth
                />
              )}
            />
          </div>
          <div className="section-style">
            <div className="content-header">
              <h1>6.Piese inlocuite</h1>
            </div>
            {/* checkbox piese */}
            <ReplacedParts
              setProducts={setProducts}
              products={products}
              parts={parts}
              disableInputs={disableInputs}
            />
          </div>
          <div className="section-style">
            <div className="content-header">
              <h1>7.Service</h1>
            </div>
            <FormControl disabled={disableInputs} component="fieldset">
              <RadioGroup
                aria-label="position"
                name="position"
                value={values.tipGarantie}
                onChange={handleChange("tipGarantie")}
                row
              >
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" />}
                  label="Garantie"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio color="secondary" />}
                  label="Garantie extinsa"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio color="default" />}
                  label="post Garantie"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="section-style">
            <div className="content-header">
              <h1>8. Recomandari pentru utilizare viitoare si alte note</h1>
            </div>
            <TextField
              id="outlined-multiline-stare"
              label=""
              multiline
              rowsMax="4"
              fullWidth={true}
              value={values.recomandari}
              onChange={handleChange("recomandari")}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              disabled={disableInputs}
            />
          </div>

          <div className="buttons-container">
            <Button
              onClick={() => history.push("/dashboard")}
              variant="contained"
              color="primary"
            >
              <ArrowBackIcon />
              Iesire
            </Button>

            <Button
              style={{ right: 0 }}
              variant="contained"
              color="primary"
              onClick={updateFisa}
              disabled={disableInputs}
            >
              Salvare
              <ArrowForwardIcon />
            </Button>
          </div>
        </FormControl>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
}));

export default ClientServiceData;
