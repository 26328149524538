import React from "react";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { Link } from "react-router-dom";

import UpdateIcon from "@material-ui/icons/Update";
import DeleteIcon from "@material-ui/icons/Delete";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { makeStyles } from "@material-ui/core";

interface TableActionButtonsProps {
  id: number;
  userRole: string[] | null;
  sheetNumber: string;
  sheetDelete: (idFisa: number, item: string) => void;
}

const TableActionButtons = ({
  id,
  userRole,
  sheetNumber,
  sheetDelete,
}: TableActionButtonsProps) => {
  const classes = useStyles();

  return (
    <div>
      {/* view button */}
      <Link to={`/customer/${id}`}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          style={{ backgroundColor: "green" }}
        >
          <VisibilityIcon style={{ marginRight: 2 }} />
          Client
        </Button>
      </Link>

      <Link to={`/customer-service/${id}`}>
        <Button variant="contained" color="primary" className={classes.button}>
          <UpdateIcon style={{ marginRight: 2 }} />
          Service
        </Button>
      </Link>

      {(userRole?.includes("1") ||
        userRole?.includes("3") ||
        userRole?.includes("4")) && (
        <Link to={`/sheet-awb/${id}`}>
          <Button
            style={{ marginRight: 5, marginBottom: 5, minHeight: 30 }}
            variant="contained"
            color="default"
            className={classes.button}
          >
            <LocalShippingIcon style={{ marginRight: 2 }} />
            Finalizare
          </Button>
        </Link>
      )}
      {userRole?.includes("4") && (
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={() => sheetDelete(id, sheetNumber)}
        >
          <DeleteIcon />
          Sterge fisa
        </Button>
      )}

      <a
        href={`https://service.e-twow.app/genereaza_fisa.php?id=${id}`}
        download="Da"
        style={{ width: "100%" }}
      >
        <Button variant="contained" color="inherit" className={classes.button}>
          <p style={{ color: "black", alignSelf: "center" }}>Generare pdf</p>
        </Button>
      </a>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
  a: {
    textDecoration: "none",
  },
  button: {
    margin: 5,
  },
}));

export default TableActionButtons;
