import React from "react";

type AuthContextType = {
  userRoleCx: Array<string> | null;
  setUserRoleCx: (value: Array<string> | null) => void;
  adminIdCx: number | null;
  setAdminIdCx: (value: number | null) => void;
  emailCx: string;
  setEmailCx: (value: string) => void;
  loggedCx: boolean;
  setLoggedCx: (value: boolean) => void;
};

export const AuthContext = React.createContext<AuthContextType | undefined>(
  undefined
);
