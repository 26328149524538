import React, { useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { FormControl, TextField } from "@material-ui/core";
import ReactLoading from "react-loading";
import useAuth from "../../Context/AuthContext/useAuth";
import useDashboard from "../../Context/DashboardContext/useDashboard";

const authError = "Datele de logare sunt gresite";

const Login = ({ history }: any) => {
  const classes = useStyles();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  const { setEmailCx, setAdminIdCx, setUserRoleCx, setLoggedCx } = useAuth()!;
  const { setFilterSediuCx } = useDashboard()!;

  const onSubmitHandler = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const reservationData = {
      email: emailValue,
      password: passwordValue,
    };
    const url = "/login.php";

    if (emailValue !== "" && passwordValue !== "") {
      setLoading(true);
      try {
        const res = await axios.post(url, reservationData, config);
        if (res.data.valid === "2") {
          setError(authError);
        } else {
          setEmailCx(emailValue);
          setAdminIdCx(res.data.id_admin);
          setUserRoleCx(res.data.rol.split(" "));
          setFilterSediuCx(res.data.id_sediu);
          console.log(res.data.rol.split(" "));
          setLoggedCx(true);
          history.push("/");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      setError("Fill in all the fields!");
    }
  };

  return (
    <div className="login_container">
      <div className="login_input_container">
        <FormControl
          className={classes.root}
          style={{ display: "flex", alignItems: "center", minWidth: 400 }}
          onSubmit={onSubmitHandler}
        >
          <h2 style={{ textAlign: "center" }} className={classes.textField}>
            {error}
          </h2>
          <TextField
            className={classes.textField}
            id="outlined-email"
            label="User"
            variant="outlined"
            onChange={(e) => setEmailValue(e.target.value)}
            fullWidth={true}
          />
          <TextField
            className={classes.textField}
            id="outlined-password"
            label="Password"
            variant="outlined"
            type="password"
            onChange={(e) => setPasswordValue(e.target.value)}
            fullWidth={true}
          />
          {loading ? (
            <ReactLoading type={"bubbles"} color={"#3f51b5"} />
          ) : (
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              size="large"
              onClick={onSubmitHandler}
              disabled={loading}
              fullWidth={true}
            >
              Login
            </Button>
          )}
        </FormControl>
      </div>
    </div>
  );
};

export default Login;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: 250,
    width: 450,
    justifyContent: "space-between",
    minWidth: 300,
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
    width: 400,
    marginBottom: 10,
    marginTop: 10,
  },
  button: {
    width: 400,
    marginTop: 20,
  },
}));
