import React from "react";
import Button from "@material-ui/core/Button";

interface SheetsFilterProps {
  filterSheetState: string | null;
  changeFilter: (filter: string) => void;
}

const SheetsFilter: React.FC<SheetsFilterProps> = ({
  filterSheetState,
  changeFilter,
}) => {
  return (
    <div>
      <Button
        style={{
          marginBottom: 22,
          marginRight: 5,
          backgroundColor:
            filterSheetState === "1" ? "rgba(0, 0, 0, 0.08)" : "transparent",
        }}
        onClick={() => changeFilter("1")}
      >
        Fise in lucru
      </Button>

      <Button
        style={{
          marginBottom: 22,
          marginRight: 5,
          backgroundColor:
            filterSheetState === "4" ? "rgba(0, 0, 0, 0.08)" : "transparent",
        }}
        onClick={() => changeFilter("4")}
      >
        Fise finalizate
      </Button>
    </div>
  );
};

export default SheetsFilter;
