import React, { useState } from "react";
import clsx from "clsx";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

export default function CreateNewPart() {
  const classes = useStyles();

  const [values, setValues] = useState({
    cod: "",
    nume_en: "",
    nume_ro: "",
    pret: "",
  });
  const [displayError, setDisplayError] = useState(false);

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  async function postDefect() {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const reservationData = {
      cod: values.cod.toUpperCase(),
      nume_en: values.nume_en,
      nume_ro: values.nume_ro,
      pret: values.pret,
    };

    if (
      values.cod !== "" &&
      values.nume_en !== "" &&
      values.nume_ro !== "" &&
      values.pret !== ""
    ) {
      try {
        const res = await axios.post(`/add_piesa.php`, reservationData, config);
        if (res.status === 200)
          setValues({ ...values, cod: "", nume_en: "", nume_ro: "", pret: "" });
      } catch (error) {
        console.log(error);
      }
    } else {
      setDisplayError(true);
    }
  }

  const newPartInputs = [
    {
      label: "Cod",
      value: values.cod.toUpperCase(),
      handleChange: handleChange("cod"),
      error: values.cod,
      type: "default",
    },
    {
      label: "Nume engleza",
      value: values.nume_en,
      handleChange: handleChange("nume_en"),
      error: values.nume_en,
      type: "default",
    },
    {
      label: "Nume romana",
      value: values.nume_ro,
      handleChange: handleChange("nume_ro"),
      error: values.nume_ro,
      type: "default",
    },
    {
      label: "Pret",
      value: values.pret,
      handleChange: handleChange("pret"),
      error: values.pret,
      type: "number",
    },
  ];

  return (
    <div className="new-parts-container">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {newPartInputs.map((newPartsInput, idx) => {
          const { label, value, handleChange, error, type } = newPartsInput!;
          return (
            <TextField
              key={idx}
              className={clsx(classes.margin, classes.textField)}
              variant="outlined"
              label={label}
              value={value}
              onChange={handleChange}
              inputProps={type === "number" ? { min: "1", step: "1" } : {}}
              type={type}
              error={displayError && error === ""}
              helperText={
                displayError && error === "" ? "Campul este gol!" : " "
              }
              required
            />
          );
        })}
      </div>
      <Button
        style={{ right: 0, width: "100%" }}
        variant="contained"
        color="primary"
        onClick={postDefect}
      >
        Salvati
      </Button>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
}));
