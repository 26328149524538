import { Route, Redirect } from "react-router-dom";
import useAuth from "../Context/AuthContext/useAuth";

const SuRoute = ({ component: Component, ...rest }: any) => {
  const { userRoleCx } = useAuth()!;

  const verifySU = userRoleCx?.includes("4");

  return (
    <Route
      {...rest}
      render={(props: any) =>
        verifySU === true ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default SuRoute;
