import React, { useState, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import UpdateIcon from "@material-ui/icons/Update";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  FormControl,
  TextField,
  Button,
} from "@material-ui/core";
import AddParts from "./CreateNewPart";
import EditPartModal from "../../utils/EditPart";
import DeleteParts from "../../utils/DeleteConfirm";
import axios from "axios";

export default function DefectsPage() {
  const classes = useStyles();

  const columns = [
    {
      name: "sheet_number",
      label: "Code",
    },
    {
      name: "creation_date",
      label: "Name_RO",
    },
    {
      name: "completion_date",
      label: "Name_EN",
    },
    {
      name: "series",
      label: "Actions",
    },
  ];

  interface PartsInterface {
    id: null | number;
    cod: string;
    nume_en: string;
    nume_ro: string;
  }

  const [parts, setParts] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [tabelPage, setTabelPage] = useState(0);
  const [tabelTotalItems, setTabelTotalItems] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [modalValues, setModalValues] = useState<PartsInterface>({
    id: null,
    cod: "",
    nume_en: "",
    nume_ro: "",
  });

  function handleClickOpen(
    id_piesa: number,
    cod: string,
    nume_en: string,
    nume_ro: string
  ) {
    setModalValues({
      id: id_piesa,
      cod: cod,
      nume_en: nume_en,
      nume_ro: nume_ro,
    });
    setOpenModal(true);
  }

  function handleClose() {
    setOpenModal(false);
  }

  // delete confirm
  const [deleteItemId, setDeleteItemId] = useState<{
    id_piesa: null | number;
    nume_en: string;
  }>({
    id_piesa: null,
    nume_en: "",
  });

  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

  const [action, setAction] = useState("");

  function handleClickOpenDeleteAlert(id_piesa: number, nume_en: string) {
    setDeleteItemId({ id_piesa, nume_en });
    setOpenDeleteAlert(true);
  }

  function handleClickCloseDeleteAlert() {
    setOpenDeleteAlert(false);
  }

  async function getParts() {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const reservationData = {
      page: tabelPage,
      items_per_page: itemsPerPage,
      query: search,
    };

    try {
      const res = await axios.post(
        `/select_piese.php`,
        reservationData,
        config
      );
      const data = res.data;
      const parts = data.slice(0, data.length - 1);
      const totalItems = data.slice(data.length - 1)[0].row_nr;
      setLoading(false);
      setParts(parts);
      setTabelTotalItems(parseInt(totalItems));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const updateDefect = async () => {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache",
          Expires: "0",
        },
      };
      const reservationData = {
        id_piesa: modalValues.id,
        cod: modalValues.cod,
        nume_en: modalValues.nume_en,
        nume_ro: modalValues.nume_ro,
      };

      try {
        const res = await axios.post(
          `/update_piese.php`,
          reservationData,
          config
        );
        if (res.status === 200) {
          setAction("");
          setLoading(false);
          setModalValues({
            id: null,
            cod: "",
            nume_en: "",
            nume_ro: "",
          });
          getParts();
        }
      } catch (error) {
        console.log(error);
      }
    };

    const deletePart = async () => {
      setLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache",
          Expires: "0",
        },
      };
      const reservationData = {
        id_piesa: deleteItemId.id_piesa,
      };

      try {
        const res = await axios.post(
          `/delete_piesa.php`,
          reservationData,
          config
        );

        if (res.status === 200) {
          setAction("");
          setLoading(false);
          setDeleteItemId({ id_piesa: null, nume_en: "" });
          getParts();
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (action === "update") updateDefect();
    else if (action === "delete") deletePart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  useEffect(() => {
    if (search) {
      if (search.length === 0) {
        getParts();
      } else if (search.length >= 2) {
        getParts();
      }
    } else {
      getParts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabelPage, itemsPerPage, search]);

  function handleChangePage(_: any, newPage: number) {
    setTabelPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setItemsPerPage(parseInt(event.target.value, 10));
  }

  return (
    <>
      <EditPartModal
        handleClose={handleClose}
        openModal={openModal}
        modalValues={modalValues}
        setModalValues={setModalValues}
        title={modalValues.nume_en}
        setAction={setAction}
      />
      <DeleteParts
        handleClose={handleClickCloseDeleteAlert}
        openModalDelete={openDeleteAlert}
        setDeleteConfirm={setAction}
        title={deleteItemId.nume_en}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          minHeight: "100%",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <FormControl style={{ width: "80%" }}>
          <AddParts />

          {/* dsadas */}
          <Paper className={classes.root}>
            <div
              style={{
                display: "flex",
                width: "90%",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 20,
              }}
            >
              <div style={{ width: 50 }} />
              <TextField
                style={{ width: 500 }}
                variant="outlined"
                label="Cauta fisa"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: search && (
                    <IconButton
                      onClick={() => setSearch("")}
                      disabled={!search}
                      style={{ order: 1 }}
                    >
                      <ClearIcon color="disabled" fontSize="small" />
                    </IconButton>
                  ),
                }}
              />

              {loading ? (
                <CircularProgress style={{ margin: "10px 30px 0 0" }} />
              ) : (
                <div style={{ width: 50 }} />
              )}
            </div>

            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => {
                      return (
                        <TableCell key={column.name}>{column.label}</TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parts?.map((row: any) => {
                    const { cod, nume_ro, nume_en, id_piesa } = row!;
                    return (
                      <TableRow key={cod}>
                        <TableCell component="th" scope="row">
                          {cod}
                        </TableCell>
                        <TableCell align="left">{nume_ro}</TableCell>
                        <TableCell align="left">{nume_en}</TableCell>
                        <TableCell align="left">
                          <div style={{ minWidth: 210 }}>
                            <Button
                              style={{ marginLeft: 5, marginRight: 5 }}
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleClickOpen(id_piesa, cod, nume_en, nume_ro)
                              }
                            >
                              <UpdateIcon />
                              Edit
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{ backgroundColor: "rgb(220, 0, 78)" }}
                              onClick={() =>
                                handleClickOpenDeleteAlert(id_piesa, nume_en)
                              }
                            >
                              <DeleteIcon />
                              Delete
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      colSpan={9}
                      count={tabelTotalItems}
                      rowsPerPage={itemsPerPage}
                      page={tabelPage}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      // ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </Paper>
        </FormControl>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
}));
