import React, { useState } from "react";

import { DashboardContext } from "./DashboardContext";

type Props = {
  children: React.ReactNode;
};
const DashboardProvider = ({ children }: Props) => {
  const [filterSediuCx, setFilterSediuCx] = useState("");
  const [filterStateCx, setFilterStateCx] = useState<string | null>("1");
  const [currentPageCx, setCurrentPageCx] = useState(0);
  const [searchValueCx, setSearchValueCx] = useState("");
  const [rowsPerPageCx, setRowsPerPageCx] = useState(25);

  return (
    <DashboardContext.Provider
      value={{
        filterSediuCx,
        setFilterSediuCx,
        filterStateCx,
        setFilterStateCx,
        currentPageCx,
        setCurrentPageCx,
        searchValueCx,
        setSearchValueCx,
        rowsPerPageCx,
        setRowsPerPageCx,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
