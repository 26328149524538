import React from "react";
import { Link } from "react-router-dom";

const routes = [
  { id: 1, route: "/", name: "Dashboard" },
  {
    id: 2,
    route: "/defects",
    name: "Defecte",
  },
  {
    id: 3,
    route: "/parts",
    name: "Piese",
  },
  {
    id: 4,
    route: "/generate-report",
    name: "Raport",
  },
];

export default function NavbarDropdown({ dashboardToggle }: any) {
  return (
    <div className="navigation_dropdown">
      <nav>
        <ul>
          {routes.map((route) => {
            return (
              <li key={route.id}>
                <Link to={route.route} onClick={dashboardToggle}>
                  {route.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}
