import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import { withStyles } from "@material-ui/core/styles";

// const CustomDialog = withStyles({
//   root: {
//     backgroundColor: "rgba(0, 0, 0, 0.5)"
//   }
// })(Dialog);

export default function EditPart({
  handleClose,
  openModal,
  modalValues,
  setModalValues,
  title,
  setAction,
}: any) {
  const handleChange = (prop: any) => (event: any) => {
    setModalValues({ ...modalValues, [prop]: event.target.value });
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      BackdropProps={{ style: { backgroundColor: "gray", opacity: 0.5 } }}
    >
      <DialogTitle id="form-dialog-title" style={{ width: "500px" }}>
        "{title}"
      </DialogTitle>
      <DialogContent>
        {Object.entries(modalValues).map(([key, value], index) => {
          // do not display input for id
          if (key !== "id")
            if (index === 1)
              // focus on first input
              return (
                <TextField
                  key={key}
                  margin="dense"
                  id={key}
                  label={key}
                  type="text"
                  fullWidth
                  value={value}
                  onChange={handleChange(key)}
                  autoFocus
                />
              );
            else
              return (
                <TextField
                  key={key}
                  margin="dense"
                  id={key}
                  label={key}
                  type="text"
                  fullWidth
                  value={value}
                  onChange={handleChange(key)}
                  autoFocus={false}
                />
              );
          else return null;
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Iesire
        </Button>
        <Button
          onClick={() => {
            setAction("update");
            handleClose();
          }}
          color="primary"
        >
          Salvare
        </Button>
      </DialogActions>
    </Dialog>
  );
}
