import useAuth from "../../Context/AuthContext/useAuth";
import useDashboard from "../../Context/DashboardContext/useDashboard";

export function Logout() {
  const { setEmailCx, setAdminIdCx, setUserRoleCx, setLoggedCx } = useAuth()!;
  const { setFilterSediuCx } = useDashboard()!;

  function triggerLogOut() {
    setEmailCx("");
    setAdminIdCx(null);
    setUserRoleCx(null);
    setFilterSediuCx("");
    setLoggedCx(false);
    return;
  }
  return (
    <button className="logout-button" onClick={triggerLogOut}>
      <p>Logout</p>
    </button>
  );
}

export default Logout;
