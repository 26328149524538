import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { TextField, Button, FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ReactLoading from "react-loading";
import axios from "axios";
import { couriers } from "../../../Config/DashboardDataConfig";
import useAuth from "../../../Context/AuthContext/useAuth";

export default function Awb() {
  const { adminIdCx, userRoleCx } = useAuth()!;
  const { id: pageId } = useParams<ParamTypes>();
  const history = useHistory();

  const [awbCode, setAwbCode] = useState("");
  const [curier, setCurier] = useState<string | null>("");

  const [loading, setLoading] = useState(true);

  const [sheetStatus, setSheetStatus] = useState<number | null>(null);

  const disableInputs = sheetStatus === 4 && !userRoleCx?.includes("4");

  interface ParamTypes {
    id: string | undefined;
  }

  function goBack() {
    return history.push("/");
  }

  async function postAwb(filterId: number) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const reservationData = {
      id_fisa: pageId,
      curier: curier,
      awb: awbCode,
      id_admin: adminIdCx,
      filter: filterId,
    };

    try {
      const res = await axios.post(`/update_awb.php`, reservationData, config);
      if (res.status === 200) goBack();
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    async function getAwb() {
      setLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache",
          Expires: "0",
        },
      };

      try {
        const res = await axios.get(`/returnAwb.php?id_fisa=${pageId}`, config);
        setAwbCode(res.data[0].awb);
        setCurier(res.data[0].curier);
        setSheetStatus(parseInt(res.data[0].status));
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    if (pageId) getAwb();
  }, [pageId]);

  return (
    <div className="customer-data-container">
      {loading ? (
        <ReactLoading type={"bubbles"} color={"#3f51b5"} />
      ) : (
        <FormControl style={{ width: "100%", height: "100%" }}>
          <div style={{ marginBottom: 50 }}>
            <Autocomplete
              id="curierat"
              fullWidth={true}
              options={couriers}
              onChange={(event, value: string | null) => setCurier(value)}
              value={curier}
              disabled={disableInputs}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Firma Curierat"
                  margin="dense"
                  variant="standard"
                  fullWidth
                />
              )}
            />
            <TextField
              autoFocus={false}
              margin="dense"
              id="name"
              label="AWB CODE"
              type="text"
              fullWidth={true}
              value={awbCode}
              onChange={(e) => setAwbCode(e.target.value)}
              disabled={disableInputs}
            />
          </div>
          <div className="buttons-container">
            <Button color="primary" variant="contained" onClick={goBack}>
              Anuleaza
            </Button>
            <div>
              <Button
                color="primary"
                variant="contained"
                onClick={() => postAwb(0)}
                disabled={disableInputs}
              >
                Ridicare personala
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => postAwb(1)}
                disabled={disableInputs}
              >
                Salvare date expediere
              </Button>
            </div>
          </div>
        </FormControl>
      )}
    </div>
  );
}
