import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TableFooter,
  TableRow,
  TablePagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Paper,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { locations } from "../../../Config/DashboardDataConfig";
import TablePaginationActions from "./TablePaginationActions";
import useAuth from "../../../Context/AuthContext/useAuth";
import useDashboard from "../../../Context/DashboardContext/useDashboard";
import TableActionButtons from "./TableActionButtons";
import SheetsFilter from "./SheetFilter";
import TableHeader from "./TableHeader";
import AvaibleLocations from "./AvaibleLocations";

import axios from "axios";

const columns = [
  {
    name: "sheet_number",
    label: "Numar fisa",
  },
  {
    name: "creation_date",
    label: "Data intrarii",
  },
  {
    name: "completion_date",
    label: "Data finalizare",
  },
  {
    name: "series",
    label: "Serie",
  },
  {
    name: "order_number",
    label: "Nr. Ordine",
  },
  {
    name: "product",
    label: "Produs",
  },
  {
    name: "customer",
    label: "Client",
  },
  {
    name: "reparation_state",
    label: "Stare reparatie",
  },
  {
    name: "action",
    label: "Butoane de gestiune",
  },
];

export function Dashboard() {
  const classes = useStyles();
  let history = useHistory();

  const { userRoleCx } = useAuth()!;

  const {
    filterSediuCx,
    filterStateCx,
    currentPageCx,
    setFilterSediuCx,
    setFilterStateCx,
    setCurrentPageCx,
    searchValueCx,
    rowsPerPageCx,
    setRowsPerPageCx,
  } = useDashboard()!;

  interface ClientDataInterface {
    id_fisa?: number;
    numar_fisa?: string;
    start?: string;
    end?: string;
    serie_produs?: string;
    nr_ordine?: string;
    produs?: string;
    nume?: string;
    status?: string;
  }

  const [clientData, setClientData] = useState<
    Array<ClientDataInterface> | undefined
  >(undefined);

  const [loading, setLoading] = useState(false);

  const [totalPages, setTotalPages] = useState(1);

  const orderDirection = "desc";
  const orderName = "numar";

  async function sheetDelete(idFisa: number, item: string) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const reservationData = {
      id_fisa: idFisa,
    };

    const r = window.confirm(`Sunteti sigur ca doriti sa stergeti ${item}?`);
    if (r === true) {
      setLoading(true);

      try {
        const res = await axios.post(
          `/delete_fisa.php`,
          reservationData,
          config
        );

        if (res.data === 200) {
          const deteleFromArray = clientData?.filter(
            (client: ClientDataInterface) => client?.id_fisa !== idFisa
          );

          setClientData(deteleFromArray);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  function createSheet() {
    history.push("/customer/0");
  }

  function changeCurrendPage(currentPage: number) {
    setCurrentPageCx(currentPage);
    return;
  }

  function changeFilter(filter: string) {
    setFilterStateCx(filter);
    changeCurrendPage(0);
    return;
  }

  function changeSuFilter(filter: string) {
    setFilterSediuCx(filter);
    changeCurrendPage(0);
    return;
  }

  useEffect(() => {
    async function getClients(param: string) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache",
          Expires: "0",
        },
      };
      // search
      const reservationData = {
        param: param,
        page: currentPageCx + 1,
        items_per_page: rowsPerPageCx,
        sediu: filterSediuCx,
        filter: filterStateCx,
        rol: userRoleCx?.join(" "),
        suFilter: filterSediuCx,
        column_name: orderName,
        order: orderDirection,
        query: searchValueCx,
      };

      setLoading(true);

      try {
        const res = await axios.post(`/showFisa.php`, reservationData, config);
        const clients = res.data?.slice(0, res.data.length - 1);
        const totalItems = res.data?.slice(res.data.length - 1)[0]?.row_nr ?? 0;
        setTotalPages(parseInt(totalItems));
        setClientData(clients);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }

    if (searchValueCx) {
      if (searchValueCx.length === 0) {
        getClients("afiseaza");
      } else if (searchValueCx.length >= 2) {
        getClients("search");
      }
    } else {
      getClients("afiseaza");
    }
  }, [
    searchValueCx,
    currentPageCx,
    filterSediuCx,
    filterStateCx,
    rowsPerPageCx,
    userRoleCx,
  ]);

  // pagination

  function handleChangePage(_: any, newPage: number) {
    changeCurrendPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPageCx(parseInt(event.target.value, 10));
  }

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <SheetsFilter
          filterSheetState={filterStateCx}
          changeFilter={changeFilter}
        />

        {(userRoleCx?.includes("4") || userRoleCx?.includes("1")) && (
          <Button className="new-sheet-button" onClick={createSheet}>
            Creaza fisa +
          </Button>
        )}
      </div>

      {userRoleCx?.includes("4") && (
        <AvaibleLocations
          locations={locations}
          changeFilter={changeSuFilter}
          filterLocation={filterSediuCx}
        />
      )}

      <Paper className={classes.root}>
        <div>
          <TableHeader loading={loading} />

          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return (
                    <TableCell key={column.name}>{column.label}</TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {clientData?.map((row: ClientDataInterface) => {
                const {
                  id_fisa,
                  numar_fisa,
                  start,
                  end,
                  serie_produs,
                  nr_ordine,
                  produs,
                  nume,
                  status,
                } = row!;
                return (
                  <TableRow key={id_fisa}>
                    <TableCell component="th" scope="row">
                      {row.numar_fisa}
                    </TableCell>
                    <TableCell align="left">{start}</TableCell>
                    <TableCell align="left">{end}</TableCell>
                    <TableCell align="left">{serie_produs}</TableCell>

                    <TableCell align="left">{nr_ordine}</TableCell>
                    <TableCell align="left">{produs}</TableCell>
                    <TableCell align="left">{nume}</TableCell>

                    {/* status */}
                    <TableCell align="left">
                      <SheetStatus status={status} />
                    </TableCell>
                    <TableCell align="left">
                      {id_fisa && numar_fisa && (
                        <TableActionButtons
                          id={id_fisa}
                          userRole={userRoleCx}
                          sheetNumber={numar_fisa}
                          sheetDelete={sheetDelete}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  colSpan={9}
                  count={totalPages}
                  rowsPerPage={rowsPerPageCx}
                  page={currentPageCx}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    </div>
  );
}

const SheetStatus = ({ status }: any) => {
  return (
    <div>
      {parseInt(status) === 1 ? (
        <p style={{ margin: 0 }}>Preluata</p>
      ) : parseInt(status) === 2 ? (
        <p style={{ color: "#FF00FF", margin: 0 }}>Constatare</p>
      ) : parseInt(status) === 3 ? (
        <p style={{ color: "blue", margin: 0 }}>Finalizare reparatie</p>
      ) : parseInt(status) === 4 ? (
        <p style={{ color: "green", margin: 0 }}>Expediat</p>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
  a: {
    textDecoration: "none",
  },
  button: {
    margin: 5,
  },
}));

export default Dashboard;
