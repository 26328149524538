import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { FormControl, Button, Grid, TextField } from "@material-ui/core";
import ReactLoading from "react-loading";
import axios from "axios";
import useAuth from "../../../Context/AuthContext/useAuth";
import useDashboard from "../../../Context/DashboardContext/useDashboard";

export function CustomerData() {
  interface ParamTypes {
    id: string | undefined;
  }

  const { id: pageId } = useParams<ParamTypes>();
  let history = useHistory();
  const classes = useStyles();

  const [values, setValues] = useState({
    id: "",
    numeClient: "",
    adress: "",
    phoneNumber: "",
    invoiceNumber: "",
    email: "",
    serie: "",
    totalKm: "",
    id_nom: "",
    modelColor: "",
    defect: "",
    condition: "",
    orderNumber: "",
  });

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [colors, setColors] = useState([
    {
      model: "Not",
      id_nom: "0",
    },
  ]);
  const [loading, setLoading] = useState(true);
  const [submitedPressedError, setSubmitedPressedError] = useState(false);
  const [sheetStatus, setSheetStatus] = useState<number | null>(null);

  const { userRoleCx, adminIdCx } = useAuth()!;
  const { filterSediuCx } = useDashboard()!;

  const disableInputs = sheetStatus === 4 && !userRoleCx?.includes("4");

  function handleDateChange(date: Date | null) {
    setSelectedDate(date ?? new Date());
  }

  const dropdownData = useCallback(async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    };

    try {
      const res = await axios.get(`/nomenclator.php`, config);
      setColors(res.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  async function postFisa() {
    if (
      values.numeClient !== "" &&
      values.adress !== "" &&
      values.phoneNumber !== "" &&
      values.modelColor !== "" &&
      values.defect !== "" &&
      values.serie !== "" &&
      values.condition !== ""
    ) {
      setSubmitedPressedError(false);

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache",
          Expires: "0",
        },
      };
      const reservationData = {
        id_fisa: pageId,
        nume: values.numeClient,
        adresa: values.adress,
        telefon: values.phoneNumber,
        factura: values.invoiceNumber,
        serie: values.serie,
        km: values.totalKm,
        id_nom: values.id_nom,
        defect_reclamat: values.defect,
        stare_produs: values.condition,
        email: values.email,
        nr_ordine: values.orderNumber,
        data_selectata: selectedDate,
        sediu: filterSediuCx,
        id_admin: adminIdCx,
      };

      try {
        const res = await axios.post(
          `/creaza_fisa.php`,
          reservationData,
          config
        );
        if (res.status === 200) history.push("/dashboard");
      } catch (error) {
        console.log(error);
      }
    } else {
      setSubmitedPressedError(true);
    }
  }

  useEffect(() => {
    dropdownData();
  }, [dropdownData]);

  useEffect(() => {
    const getValues = async () => {
      setLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, no-store",
          Pragma: "no-cache",
          Expires: "0",
        },
      };
      const reservationData = {
        id_fisa: pageId,
      };

      try {
        const res = await axios.post(`/pas1.php`, reservationData, config);

        res.data?.forEach((clientData: any) => {
          const {
            data_selectata,
            id_fisa,
            nume,
            adresa,
            telefon,
            nr_factura,
            email,
            serie,
            km,
            id_nom,
            defect_reclamat,
            stare_produs,
            nr_ordine,
            status,
          } = clientData!;
          const modelColorName = colors?.filter(
            (color) => id_nom === color?.id_nom
          )[0];

          setSelectedDate(data_selectata ?? new Date());

          setValues((values) => ({
            ...values,
            id: id_fisa,
            numeClient: nume,
            adress: adresa,
            phoneNumber: telefon,
            invoiceNumber: nr_factura,
            email: email,
            serie: serie,
            totalKm: km,
            id_nom: id_nom,
            modelColor: modelColorName ? modelColorName.model : "",
            defect: defect_reclamat,
            condition: stare_produs,
            orderNumber: nr_ordine,
          }));
          setSheetStatus(parseInt(status));
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    if (colors[0].model !== "Not") getValues();
  }, [colors, pageId]);

  const handleChange = (prop: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues({
      ...values,
      [prop]:
        prop === "serie"
          ? event.target.value
              .toUpperCase()
              .replace(/[&\\#,+()$~%.'":*?<>{}!^-_=+|`~@ ]/g, "")
          : event.target.value,
    });
  };

  const firstRowInputs = [
    {
      label: "Nume client",
      type: "text",
      value: values.numeClient,
      handleChange: handleChange("numeClient"),
      inputProps: {},
      error: submitedPressedError && values.numeClient === "",
      helperText:
        submitedPressedError && values.numeClient === ""
          ? "Campul este gol!"
          : " ",
      disabled: disableInputs,
    },
    {
      label: "Adresa",
      type: "text",
      value: values.adress,
      handleChange: handleChange("adress"),
      inputProps: {},
      error: submitedPressedError && values.adress === "",
      helperText:
        submitedPressedError && values.adress === "" ? "Campul este gol!" : " ",
      disabled: disableInputs,
    },
    {
      label: "Nr. tel",
      type: "tel",
      value: values.phoneNumber,
      handleChange: handleChange("phoneNumber"),
      inputProps: {
        maxLength: 15,
      },
      error: submitedPressedError && values.phoneNumber === "",
      helperText:
        submitedPressedError && values.phoneNumber === ""
          ? "Campul este gol!"
          : " ",
      disabled: disableInputs,
    },
  ];

  const secondRowInputs = [
    {
      label: "Numar factura",
      type: "text",
      value: values.invoiceNumber,
      handleChange: handleChange("invoiceNumber"),
      inputProps: {},
      error: false,
      helperText: null,
      disabled: disableInputs,
    },
    {
      label: "Nr. de ordine",
      type: "text",
      value: values.orderNumber,
      handleChange: handleChange("orderNumber"),
      inputProps: {},
      error: false,
      helperText: null,
      disabled: disableInputs,
    },
    {
      label: "Email",
      type: "text",
      value: values.email,
      handleChange: handleChange("email"),
      inputProps: {
        maxLength: 15,
      },
      error: false,
      helperText: null,
      disabled: disableInputs,
    },
  ];

  return (
    <div className="customer-data-container">
      {loading ? (
        <ReactLoading type={"bubbles"} color={"#3f51b5"} />
      ) : (
        <FormControl style={{ width: "100%", height: "100%" }}>
          <div className="section-style">
            <div className="content-header">
              <h1
                style={{
                  width: "50%",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                1.Date client
              </h1>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    style={{ marginLeft: "auto", marginRight: 0 }}
                    label="Data fisei"
                    value={selectedDate}
                    format="dd/MM/yyyy"
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    disabled={disableInputs}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </div>
            <div className="row-container">
              {firstRowInputs.map((firstRowInput, idx) => {
                const {
                  label,
                  value,
                  error,
                  handleChange,
                  helperText,
                  inputProps,
                  type,
                  disabled,
                } = firstRowInput;
                return (
                  <TextField
                    key={idx}
                    className={clsx(classes.margin, classes.textField)}
                    variant="outlined"
                    type={type}
                    label={label}
                    value={value}
                    onChange={handleChange}
                    error={error}
                    helperText={helperText}
                    inputProps={inputProps}
                    disabled={disabled}
                  />
                );
              })}
            </div>
            <div className="row-container">
              {secondRowInputs.map((secondRowInput, idx) => {
                const {
                  label,
                  value,
                  error,
                  handleChange,
                  helperText,
                  inputProps,
                  type,
                  disabled,
                } = secondRowInput;
                return (
                  <TextField
                    key={idx}
                    className={clsx(classes.margin, classes.textField)}
                    variant="outlined"
                    type={type}
                    label={label}
                    value={value}
                    onChange={handleChange}
                    error={error}
                    helperText={helperText}
                    inputProps={inputProps}
                    disabled={disabled}
                  />
                );
              })}
            </div>
          </div>
          <div className="section-style">
            <div className="content-header">
              <h1>2.Date produs</h1>
            </div>
            <div className="row-container">
              <TextField
                className={clsx(classes.margin, classes.textField)}
                variant="outlined"
                label="Serie"
                value={values.serie}
                onChange={handleChange("serie")}
                error={submitedPressedError && values.serie === ""}
                helperText={
                  submitedPressedError && values.serie === ""
                    ? "Campul este gol!"
                    : " "
                }
                disabled={disableInputs}
              />
              <TextField
                className={clsx(classes.margin, classes.textField)}
                variant="outlined"
                label="Km total"
                value={values.totalKm}
                onChange={handleChange("totalKm")}
                disabled={disableInputs}
              />
              {/* da */}
              <Autocomplete
                id="autocomplete-model"
                className={clsx(classes.margin, classes.autocomplete)}
                options={colors}
                getOptionLabel={(option) => option?.model}
                inputValue={values.modelColor}
                disabled={disableInputs}
                onChange={(event, value) => {
                  if (value !== null) {
                    return setValues({
                      ...values,
                      modelColor: value.model,
                      id_nom: value.id_nom,
                    });
                  } else return setValues({ ...values, modelColor: "" });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Model/Culoare"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange("modelColor")}
                    helperText={
                      submitedPressedError && values.modelColor === ""
                        ? "Campul este gol!"
                        : " "
                    }
                    error={submitedPressedError && values.modelColor === ""}
                  />
                )}
              />
            </div>
          </div>
          <div className="section-style">
            <div className="content-header">
              <h1>3.Defect reclamat</h1>
            </div>
            <TextField
              style={{ flexBasis: "auto" }}
              id="outlined-multiline-defect"
              label=""
              multiline
              rowsMax="4"
              value={values.defect}
              onChange={handleChange("defect")}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              error={submitedPressedError && values.defect === ""}
              helperText={
                submitedPressedError && values.defect === ""
                  ? "Campul este gol!"
                  : " "
              }
              disabled={disableInputs}
            />
          </div>
          <div className="section-style">
            <div className="content-header">
              <h1>4.Stare produs si accesorii primite/trimise</h1>
            </div>
            <TextField
              style={{ flexBasis: "auto" }}
              id="outlined-multiline-stare"
              label=""
              multiline
              rowsMax="4"
              value={values.condition}
              onChange={handleChange("condition")}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              error={submitedPressedError && values.condition === ""}
              helperText={
                submitedPressedError && values.condition === ""
                  ? "Campul este gol!"
                  : " "
              }
              disabled={disableInputs}
            />
          </div>
          <div className="buttons-container">
            <Button
              onClick={() => history.push("/dashboard")}
              style={{ right: 0 }}
              variant="contained"
              color="primary"
            >
              <ArrowBackIcon />
              Iesire
            </Button>

            <Button
              onClick={postFisa}
              style={{ right: 0 }}
              variant="contained"
              color="primary"
              disabled={disableInputs}
            >
              Salvati
              <ArrowForwardIcon />
            </Button>
          </div>
        </FormControl>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    // flexBasis: 200,
    width: "100%",
  },
  autocomplete: {
    width: "100%",
  },
}));

export default CustomerData;
