import React, { useState } from "react";

import { AuthContext } from "./AuthContext";

type Props = {
  children: React.ReactNode;
};
const AuthProvider = ({ children }: Props) => {
  const [userRoleCx, setUserRoleCx] = useState<Array<string> | null>(null);

  const [adminIdCx, setAdminIdCx] = useState<number | null>(null);

  const [emailCx, setEmailCx] = useState("");

  const [loggedCx, setLoggedCx] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        userRoleCx,
        setUserRoleCx,
        adminIdCx,
        setAdminIdCx,
        emailCx,
        setEmailCx,
        loggedCx,
        setLoggedCx,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
