import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import UpdateIcon from "@material-ui/icons/Update";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddDefect from "./AddDefect";
import EditDefects from "../../utils/EditPart";
import DeleteDefects from "../../utils/DeleteConfirm";
import axios from "axios";
import { FormControl } from "@material-ui/core";

export default function DefectsPage() {
  const [defecte, setDefecte] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [modalValues, setModalValues] = useState<{
    id: null | number;
    defect: string;
    timp: string;
  }>({
    id: null,
    defect: "",
    timp: "",
  });
  const [action, setAction] = useState("");
  const [deleteItemId, setDeleteItemId] = useState({
    id_defect: "",
    defect: "",
  });
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

  function handleClickOpen(id_defect: number, defect: string, timp: string) {
    setModalValues({
      id: id_defect,
      defect: defect,
      timp: timp,
    });
    setOpenModalEdit(true);
  }

  function handleClose() {
    setOpenModalEdit(false);
  }

  function handleClickOpenDeleteAlert(id_defect: string, defect: string) {
    setDeleteItemId({ id_defect, defect });
    setOpenDeleteAlert(true);
  }

  function handleClickCloseDeleteAlert() {
    setOpenDeleteAlert(false);
  }

  let findDefect = defecte.filter((defect: any) => {
    return (
      defect.defect.indexOf(search) !== -1 || defect.timp.indexOf(search) !== -1
    );
  });

  async function getDefecte() {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    setLoading(true);
    try {
      const res = await axios.get(`/defecte.php`, config);
      setDefecte(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const updateDefect = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const reservationData = {
      id_defect: modalValues.id,
      defect: modalValues.defect,
      timp: modalValues.timp,
    };
    try {
      const res = await axios.post(
        `/update_defect.php`,
        reservationData,
        config
      );

      if (res.status === 200) {
        setAction("");
        setLoading(false);
        setModalValues({
          id: null,
          defect: "",
          timp: "",
        });
        getDefecte();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteDefect = async () => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const reservationData = {
      id_defect: deleteItemId.id_defect,
    };
    try {
      const res = await axios.post(
        `/delete_defect.php`,
        reservationData,
        config
      );
      if (res.status === 200) {
        setAction("");
        setLoading(false);
        setDeleteItemId({
          id_defect: "",
          defect: "",
        });
        getDefecte();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDefecte();
  }, []);

  useEffect(() => {
    if (action === "update") updateDefect();
    else if (action === "delete") deleteDefect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  return (
    <>
      <EditDefects
        handleClose={handleClose}
        openModal={openModalEdit}
        modalValues={modalValues}
        setModalValues={setModalValues}
        title={modalValues.defect}
        setAction={setAction}
      />
      <DeleteDefects
        handleClose={handleClickCloseDeleteAlert}
        openModalDelete={openDeleteAlert}
        setDeleteConfirm={setAction}
        title={deleteItemId.defect}
      />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        className="kt-portlet"
        style={{ minHeight: "100%" }}
      >
        <FormControl style={{ width: "80%" }}>
          <AddDefect />
          <div className="body-table">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "20px 25px",
              }}
            >
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                  <SearchIcon />
                </Grid>
                <Grid item>
                  <TextField
                    id="input-with-icon-grid"
                    label="Cautati defecte"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    value={search}
                  />
                </Grid>
              </Grid>
              {loading && (
                <CircularProgress style={{ margin: "10px 30px 0 0" }} />
              )}
            </div>
            <table
              style={{
                tableLayout: "fixed",
                width: "100%",
              }}
            >
              <thead>
                <tr>
                  <th className="table-items">Nume</th>
                  <th className="table-items">Timp reparatie</th>
                  <th className="table-items">Butoane de configurare</th>
                </tr>
              </thead>
              <tbody>
                {findDefect.length < 1 ? (
                  <tr>
                    <td style={{ display: "hidden" }}></td>
                    <td>
                      <p style={{ textAlign: "center", margin: "30px" }}>
                        Este gol
                      </p>
                    </td>
                  </tr>
                ) : (
                  findDefect.map((elem: any, rowIndex: number) => (
                    <tr key={rowIndex}>
                      <th className="table-items">{elem.defect}</th>
                      <th className="table-items">{elem.timp}</th>
                      <th className="table-items">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          {/* view button */}
                          <Button
                            style={{ marginLeft: 5, marginRight: 5 }}
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleClickOpen(
                                elem.id_defect,
                                elem.defect,
                                elem.timp
                              )
                            }
                          >
                            <UpdateIcon />
                            Edit
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ backgroundColor: "rgb(220, 0, 78)" }}
                            onClick={() =>
                              handleClickOpenDeleteAlert(
                                elem.id_defect,
                                elem.defect
                              )
                            }
                          >
                            <DeleteIcon />
                            Delete
                          </Button>
                        </div>
                      </th>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </FormControl>
      </Grid>
    </>
  );
}
