import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";

import { IconButton } from "@material-ui/core";
import useDashboard from "../../../Context/DashboardContext/useDashboard";

interface TableHeaderProps {
  loading: boolean;
}

export default function TableHeader({ loading }: TableHeaderProps) {
  const { searchValueCx, filterStateCx, setSearchValueCx } = useDashboard()!;

  function handleSearchValue(value: string) {
    setSearchValueCx(value);
  }

  return (
    <div
      style={{
        display: "flex",
        width: "90%",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          minWidth: 250,
        }}
      >
        <h1 style={{ color: "black", marginRight: 20 }}>
          {filterStateCx === "1" ? "Fise in lucru" : "Fise finalizate"}
        </h1>

        {loading ? <CircularProgress size={24} /> : null}
      </div>

      <TextField
        style={{ width: 500 }}
        variant="outlined"
        label="Cauta fisa"
        value={searchValueCx}
        onChange={(e) => handleSearchValue(e.target.value)}
        InputProps={{
          startAdornment: searchValueCx && (
            <IconButton
              onClick={() => handleSearchValue("")}
              disabled={!searchValueCx}
              style={{ order: 1 }}
            >
              <ClearIcon color="disabled" fontSize="small" />
            </IconButton>
          ),
        }}
      />
      <div style={{ width: 200 }} />
    </div>
  );
}
