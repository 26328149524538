import Routes from "./Components/Navigation/Routes";
import "./Components/styles/App.css";
import "./Components/styles/Navbar.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";

import { faBars } from "@fortawesome/free-solid-svg-icons";

import AuthProvider from "./Components/Context/AuthContext/AuthProvider";
import DashboardProvider from "./Components/Context/DashboardContext/DashboardProvider";

library.add(fab, faBars);

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <DashboardProvider>
          <Routes />
        </DashboardProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
