import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavbarDropdown from "./NavbarDropdown";
import Logout from "../Pages/Auth/Logout";
import { Link } from "react-router-dom";

import useAuth from "../Context/AuthContext/useAuth";

// @ts-ignore
import Logo from "../../assets/logo.png";

function Navbar() {
  const { userRoleCx, loggedCx } = useAuth()!;

  const verifySU = userRoleCx?.includes("4");

  const [navigationDropdownShow, setNavigationDropdownShow] = useState(false);

  const dashboardToggle = () => {
    setNavigationDropdownShow((prevState) => !prevState);
  };

  return (
    <header>
      {loggedCx && (
        <>
          <div className="navbar_container">
            {verifySU && (
              <button onClick={dashboardToggle} className="hamburger-menu">
                <FontAwesomeIcon
                  icon="bars"
                  color={"white"}
                  style={{ height: 30, width: 30 }}
                />
              </button>
            )}

            <Link to="/">
              <img
                src={Logo}
                alt="e-twow logo"
                style={{ width: 100, height: "auto" }}
              />
            </Link>

            <Logout />
          </div>
          {navigationDropdownShow && (
            <NavbarDropdown dashboardToggle={dashboardToggle} />
          )}
        </>
      )}
    </header>
  );
}

export default Navbar;
