import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// import { withStyles } from "@material-ui/core/styles";

// const CustomDialog = withStyles({
//   root: {
//     backgroundColor: "rgba(0, 0, 0, 0.5)"
//   }
// })(Dialog);

export default function AlertDialog({
  handleClose,
  openModalDelete,
  setDeleteConfirm,
  title,
}: any) {
  return (
    <div>
      <Dialog
        open={openModalDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ width: "500px" }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you wan't to delete it ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Anuleaza
          </Button>
          <Button
            onClick={() => {
              setDeleteConfirm("delete");
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            Confirma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
