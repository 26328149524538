import React from "react";

type DashboardContextType = {
  filterSediuCx: string;
  setFilterSediuCx: (value: string) => void;
  filterStateCx: string | null;
  setFilterStateCx: (value: string | null) => void;
  currentPageCx: number;
  setCurrentPageCx: (value: number) => void;
  searchValueCx: string | null;
  setSearchValueCx: (value: string) => void;
  rowsPerPageCx: number;
  setRowsPerPageCx: (value: number) => void;
};

export const DashboardContext = React.createContext<
  DashboardContextType | undefined
>(undefined);
