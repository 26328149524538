import React from "react";
import Button from "@material-ui/core/Button";

interface AvaibleLocationsProps {
  locations: Array<{
    id: string;
    name: string;
  }>;
  changeFilter: (id: string) => void;
  filterLocation: string;
}

export default function AvaibleLocations({
  locations,
  changeFilter,
  filterLocation,
}: AvaibleLocationsProps) {
  return (
    <div>
      <h2>Sedii disponibile</h2>
      {locations?.map(({ id, name }) => {
        return (
          <Button
            style={{
              marginBottom: 22,
              marginTop: 5,
              marginRight: 5,
              backgroundColor:
                id === filterLocation ? "rgba(0, 0, 0, 0.08)" : "transparent",
            }}
            key={id}
            onClick={() => changeFilter(id)}
          >
            {name}
          </Button>
        );
      })}
    </div>
  );
}
