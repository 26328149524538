import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

export default function AddDefect() {
  const classes = useStyles();
  const [addDefect, setAddDefect] = useState("");
  const [timpDefect, setTimpDefect] = useState("");
  const [displayError, setDisplayError] = useState(false);

  function postDefect() {
    if (addDefect !== "" && timpDefect !== "") {
      fetchPostDefect();
    } else {
      setDisplayError(true);
    }
  }

  async function fetchPostDefect() {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
    const reservationData = {
      defect: addDefect,
      timp: timpDefect,
    };

    try {
      const res = await axios.post(`/add_defect.php`, reservationData, config);
      if (res.status === 200) {
        setAddDefect("");
        setTimpDefect("");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="new-parts-container">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <TextField
          id="outlined-multiline-defect"
          label="Adaugati defect"
          fullWidth={true}
          value={addDefect}
          onChange={(e) => setAddDefect(e.target.value)}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          required
          error={displayError && addDefect === ""}
          helperText={
            displayError && addDefect === "" ? "Campul este gol!" : " "
          }
        />
        <TextField
          id="outlined-multiline-timp"
          label="Timp reparatie"
          fullWidth={true}
          value={timpDefect}
          onChange={(e: any) =>
            setTimpDefect(e.target.value >= 1 ? e.target.value : "")
          }
          inputProps={{ min: "1", step: "1" }}
          type="number"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          required
          error={displayError && timpDefect === ""}
          helperText={
            displayError && timpDefect === "" ? "Campul este gol!" : " "
          }
        />
      </div>
      <Button
        style={{ width: "100%" }}
        variant="contained"
        color="primary"
        onClick={postDefect}
      >
        Salvati
      </Button>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    // flexBasis: 200,
    margin: "20px 5px",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
}));
